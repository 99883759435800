<template>
  <div class="order_container">
    <!-- 弹窗 -->
    <van-popup class="popup" v-model="popup" style="width: 70%;">
      <div class="con">
        温馨提示：挂号成功后，如要退号请在就诊前一个小时提前退号，过时不退挂号费。
      </div>
      <div class="button">
        <van-button @click="conf()" type="primary">确认</van-button>
      </div>
    </van-popup>
    <div class="header">
      <div class="info">
        <div>科室：{{ deptName }}</div>
        <div>医生：{{ doctorName }}</div>
        <div>就诊人：{{ name }}</div>
        <div>就诊时间：{{ searchDate }} {{ beginTime }}-{{ endTime }}</div>
      </div>

      <div class="content">
        <van-radio-group v-model="radio">
          <van-cell-group>
            <van-cell title="微信支付" clickable data-name="1">
              <div></div>
              <van-radio slot="right-icon" name="1" checked-color="#67C23A" />
            </van-cell>
            <!-- 暂未开放 -->
          </van-cell-group>
        </van-radio-group>
      </div>
      <div class="cost">诊查费：{{ regFee }}元</div>
    </div>
    <van-button
      :disabled="disabled"
      type="primary"
      block
      class="btn"
      @click="order"
      >支&nbsp;&nbsp;&nbsp;付</van-button
    >
  </div>
</template>

<script>
import { jsonPost, formPost } from "@/baseAPI";
import qs from "qs";
import { load } from "mime";
export default {
  name: "Order",
  data() {
    return {
      appointmentId: "",
      orderNum: "",
      name: "",
      deptName: "",
      doctorName: "",
      timeSlot: "",
      beginTime: "",
      endTime: "",
      regFee: "",
      medicalCardNo: "",
      orderRecordId: "",
      position: "",
      payData: {},
      searchDate: "",
      disabled: false,
      popup: false,
      radio: "1"
    };
  },
  created() {
    this.appointmentId = this.$route.query.appointmentId;
    this.orderNum = this.$route.query.orderNum;
    this.name = this.$route.query.name;
    this.deptName = this.$route.query.deptName;
    this.doctorName = this.$route.query.doctorName;
    this.timeSlot = this.$route.query.timeSlot;
    this.searchDate = this.$route.query.searchDate;
    this.beginTime = this.$route.query.beginTime;
    this.endTime = this.$route.query.endTime;
    this.regFee = this.$route.query.regFee;
    this.medicalCardNo = this.$route.query.medicalCardNo;
    this.orderRecordId = this.$route.query.orderRecordId;
    this.position = this.$route.query.position;
    if (
      this.deptName == "儿童保健门诊" ||
      this.deptName == "儿童行为发育门诊"
    ) {
      this.popup = true;
    }
    localStorage.setItem("orderNum", this.orderNum);
    localStorage.setItem("name", this.name);
  },
  methods: {
    conf() {
      this.popup = false;
    },
    order() {
      this.disabled = true;
      this.unifiedOrder();
    },
    //预约挂号下单
    async unifiedOrder() {
      this.$toast.loading({
        duration: 0,
        message: "加载中...",
        forbidClick: true
      });
      const { data: res } = await jsonPost("/wxapp/pay/wxpay/unifiedOrder", {
        openId: window.localStorage.getItem("openId"),
        name: this.name,
        regFee: this.regFee,
        appointmentId: this.appointmentId,
        orderRecordId: this.orderRecordId,
        orderNum: this.orderNum,
        recordType: "1"
      });
      this.$toast.clear();
      if (res.code === 0) {
        this.payData = res.data;
        this.pay();
      } else {
        this.$toast.fail(res.msg);
      }
    },
    pay() {
      let that = this;
      function onBridgeReady() {
        WeixinJSBridge.invoke(
          "getBrandWCPayRequest",
          {
            appId: "wxcdae494f7e9422e7",
            timeStamp: that.payData.timeStamp,
            nonceStr: that.payData.nonceStr,
            package: that.payData.package,
            signType: that.payData.signType,
            paySign: that.payData.paySign
          },
          function(res) {
            if (res.err_msg === "get_brand_wcpay_request:ok") {
              that.$router.push({
                path: "/register/pay",
                query: {
                  name: that.name,
                  deptName: that.deptName,
                  doctorName: that.doctorName,
                  timeSlot: that.searchDate,
                  beginTime: that.beginTime,
                  endTime: that.endTime,
                  regFee: that.regFee,
                  outTradeNo: that.payData.outTradeNo,
                  patCardNo: that.medicalCardNo,
                  position: that.position
                }
              });
            } else if (res.err_msg === "get_brand_wcpay_request:cancel") {
              that.$toast("支付未完成");
              setTimeout(() => {
                that.$router.push({
                  path: "/order/register",
                  query: {
                    patCardNo: that.medicalCardNo
                  }
                });
              }, 2000);
            } else {
              that.$toast(res.err_msg);
            }
          }
        );
      }
      if (typeof WeixinJSBridge == "undefined") {
        if (document.addEventListener) {
          document.addEventListener(
            "WeixinJSBridgeReady",
            onBridgeReady,
            false
          );
        } else if (document.attachEvent) {
          document.attachEvent("WeixinJSBridgeReady", onBridgeReady);
          document.attachEvent("onWeixinJSBridgeReady", onBridgeReady);
        }
      } else {
        onBridgeReady();
      }
    }
  }
};
</script>

<style lang="less" scope>
.order_container .popup {
  .con {
    padding: 10px 30px;
    font-size: 32px;
  }

  button {
    width: 100%;
    background-color: #1cd66c;
    color: #eee;
  }
}

.order_container .header .info {
  font-size: 40px;
  margin-left: 30px;
  border-bottom: 1px solid skyblue;
  padding: 20px 0;
}

.order_container .header .info div {
  padding: 10px 0;
}

.order_container .header .cost {
  padding: 30px 0;
  font-size: 40px;
  border-bottom: 2px solid skyblue;
  margin-left: 30px;
}

.order_container .btn {
  position: fixed;
  left: 0;
  bottom: 60px;
  z-index: 1;
  width: 100%;
  font-size: 40px;
  height: 100px;
}
</style>
